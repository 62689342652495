import { Injectable, inject } from '@angular/core';
import { THEME } from '../enums/theme.enum';
import { LocalStorageService } from './local-storage.service';
import { Observable, of } from 'rxjs';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  protected ls = inject(LocalStorageService);
  protected coreService = inject(CoreService);

  get themeMode(): THEME {
    return this.ls.getItem('themeMode');
  }

  init(): Observable<void> {
    return of(this.setTheme(!this.themeMode ? THEME.DARK : this.themeMode));
  }

  setTheme(themeMode: THEME) {
    if (this.coreService.isBrowser()) {
      this.ls.setItem('themeMode', themeMode);
      document.body.classList.add(themeMode);
      document.body.classList.remove(themeMode === THEME.WHITE ? THEME.DARK : THEME.WHITE);
    }
  }
}
