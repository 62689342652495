import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { UserModel } from '../models/user.model';

export const AuthResolver: ResolveFn<Observable<UserModel>> = () => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return <Observable<UserModel>>authService.beforeRequest().pipe(
    tap((data: any) => {
      if (data?.token) {
        return data;
      }
      return authService.auth().subscribe((user: any) => user);
    })
  );
};
